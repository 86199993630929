<template>
  <div style="background-color: #f6f6f6; min-height: 100vh">
    <div style="background-color: #fff">
      <!-- banner -->
      <van-swipe class="homebanner" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="banner in details.Imgs.split(',')" :key="banner"
          ><img :src="banner" style="height: 250px" />
        </van-swipe-item>
      </van-swipe>
      <div class="bg">
        <div class="title">
          {{ details.LeaseModeDesc }} | {{ details.RoomSource }}
        </div>
        <div class="money">
          {{ details.Price
          }}<i style="font-style: normal; font-size: 13px; margin-right: 10px"
            >元/月</i
          >
          <i style="font-style: normal; font-size: 13px; color: #666666">{{
            details.Deposit
          }}</i>
        </div>
        <div class="des">
          <span>
            <div class="max">{{ details.RoomType }}</div>
            <div class="min">房型</div>
          </span>
          <span>
            <div>{{ details.Area }}m²</div>
            <div class="min">面积</div>
          </span>
          <span>
            <div>{{ details.Floor }}</div>
            <div class="min">楼层</div>
          </span>
          <span>
            <div>{{ details.Toward }}</div>
            <div class="min">朝向</div>
          </span>
        </div>
      </div>
    </div>
    <div class="mid">
      <div>看房必读</div>
      <div>
        <i
          style="
            font-style: normal;
            font-size: 13px;
            font-weight: 400;
            color: #666666;
          "
          >看房时间</i
        >&nbsp;&nbsp;&nbsp;{{ details.OpenHome }}
      </div>
      <div>
        <i
          style="
            font-style: normal;
            font-size: 13px;
            font-weight: 400;
            color: #666666;
          "
          >入住要求</i
        >&nbsp;&nbsp;&nbsp;{{ details.InRequire }}
      </div>
      <div @click="Click" class="yuyue">预约看房</div>
      <img
        class="yuyue"
        style="width: 5px; right: 6%"
        src="https://dximg.dexian.ren/right.png"
        alt=""
      />
    </div>
    <div class="mid" style="height: 95px; padding: 0 5%">
      <img
        src="https://dximg.dexian.ren/map.png"
        alt=""
        style="width: 100%; height: 95px; position: absolute"
      />
      <div class="add">
        <img
          src="https://dximg.dexian.ren/address.png"
          style="width: 12px; vertical-align: middle; margin-right: 2%"
        />{{ details.Address }}
      </div>
      <div @click="gotominiProgram(details)" class="yuyue">地图/周边</div>
      <img
        class="yuyue"
        style="width: 5px; right: 6%"
        src="https://dximg.dexian.ren/right.png"
        alt=""
      />
    </div>

    <div class="mid" style="margin-bottom: 1px">
      <div>看房必读</div>
      <div style="font-weight: 400">
        {{ details.Describe }}
      </div>
    </div>

    <div class="bottom">
      <van-button class="submit" @click="Click">预约看房</van-button>
    </div>
    <!-- 弹出框 预约看房 -->
    <van-popup
      v-model="addressShow"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div>
        <van-cell-group>
          <van-datetime-picker
            @confirm="PlaceOrder"
            @cancel="addressShow = false"
            v-model="datafrom.ShowTime"
            type="datetime"
            :columns-order="['year', 'month', 'day', 'hour', 'minute']"
            :formatter="formatter"
            title="请选择看房时间"
            :min-date="minDate"
            :max-date="maxDate"
          />
        </van-cell-group>
        <!-- <div class="bottom" style="position: absolute; width: 100%; bottom: 0">
          <van-button
            style="border-radius: 24px; width: 26%"
            class="submit"
            @click="PlaceOrder"
            >确定</van-button
          >
        </div> -->
      </div>
    </van-popup>
  </div>
</template>
  
  <script>
import { WxGetLease, WxPreRoom } from "@/api/RealInfo";
import {
  WxGenerateOrder,
  WeGetMyBindInfoPage,
  WxPlaceOrder,
  WeSaveMessOrderService,
  WeGetOrderFoodList,
} from "@/api/shop";
import "swiper";
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
Vue.use(Toast);
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      minDate: new Date(),
      maxDate: new Date(2038, 12, 30),
      datafrom: {
        LeaseId: "", // 房源ID
        ShowTime: new Date(), // 看房时间
      },
      details: {},
      list: [],
      goodsid: "", //商品id
      addressShow: false, //弹出框
      addressList: [], //地址
      checked: "", //选中内容
      usreData: [], //会员信息
      shopNum: "", //商品数量
      orderid: "", //订单id
      shoppingList: [], //购物车商品列表
    };
  },
  created() {
    this.wxGetPromotion(); //商品详情
  },
  methods: {
    gotominiProgram(details) {
      console.log(details);
      if (details.Coordinate == "" || details.Coordinate == null) {
        Toast.fail("暂无位置信息");
      } else {
        this.nameCc = {
          Navigation: "导航",
          Coordinate: details.Coordinate,
        };
        if (window.__wxjs_environment === "miniprogram") {
          window.wx.miniProgram.navigateTo({
            url: "../openminip/openminip?name=" + JSON.stringify(this.nameCc),
          });
        } else {
          alert("请在小程序中打开");
        }
      }
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "点";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    // 数量改变
    Goodschange(row) {
      WeSaveMessOrderService({
        //添加订单商品
        OpenID: getOpenId(),
        OrderId: this.orderid,
        FoodId: this.goodsid,
        FoodNum: row,
      }).then((res) => {
        console.log(res);
      });
    },
    // 选中收货地址
    radioClick(row) {
      console.log(row);
      this.checked = row;
    },
    //格式化日期
    filteryear(val) {
      if (val.StartDate != null && val.StartDate != "") {
        let gstime = val.StartDate.substring(0, 10);
        return gstime;
      }
    },
    // 获取房源详情
    wxGetPromotion() {
      this.goodsid = this.$route.params.LeaseId;
      WxGetLease({ leaseId: this.goodsid }).then((res) => {
        this.details = res.data.data;
        console.log(this.details, "this.details");
        // this.getshoppingList(this.orderid);
      });
    },
    // 发起订单
    getShopOrder() {
      WxPlaceOrder({
        //提交订单
        OpenID: getOpenId(),
        OrderId: orderid,
        BindId: checked,
        DeliveryMode: 1,
      }).then((res) => {});
    },
    // 预约看房
    Click() {
      this.addressShow = true;
    },

    PlaceOrder() {
      console.log(this.datafrom, "datafrom");
      if (this.datafrom.ShowTime == "") {
        Toast.fail("请输入看房时间");
        return false;
      }
      WxPreRoom({
        LeaseId: this.goodsid,
        OpenID: getOpenId(),
        ShowTime: this.datafrom.ShowTime,
      }).then((res) => {
        if (res.data.code == 0) {
          Toast.success("预约成功!");
          this.addressShow = false;
          this.datafrom.ShowTime = "";
        } else {
          Toast.fail(res.data.msg);
          this.addressShow = false;
          this.datafrom.ShowTime = "";
        }
      });
    },
  },
};
</script>
  
  <style scoped>
body {
  background-color: #f6f6f6;
}
.bg {
  width: 90%;
  margin: 15px auto;
}
.homebanner {
  width: 100%;
  /* margin-top: 15px; */
}

.homebanner img {
  width: 100%;
}

.title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #0c0c0c;
}

.money {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #fc4839;
  margin-top: 10px;
}

.des {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 5%;
  height: 74px;
  border-top: 1px solid #f4f4f4;
  text-align: center;
}

.max {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #0c0c0c;
}

.min {
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #999999;
}

.mid {
  position: relative;
  box-sizing: border-box;
  /* height: 97px; */
  padding: 2% 5%;
  background-color: #fff;
  margin-bottom: 12px;
}

.mid div:nth-child(1) {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #0c0c0c;
  margin: 7px 0;
}
.mid div:nth-child(2),
.mid div:nth-child(3) {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #0c0c0c;
  line-height: 22px;
}

.mid .yuyue {
  font-size: 13px !important;
  font-family: PingFang SC;
  font-weight: 500 !important;
  color: #4b6c8d !important;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

.add {
  width: 88%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.submit {
  width: 90%;
  height: 43.5px;
  background: #fc4839;
  border-radius: 8px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.bottom {
  text-align: center;
  height: 70px;
  line-height: 60px;
  background: #ffffff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.08);
}
</style>